/**
=========================================================
* Muwafiq - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import EditTag from "cruds/tag-management/edit-tag";
import NewTag from "cruds/tag-management/new-tag";
import EditStatus from "cruds/status-management/edit-status";
import NewStatus from "cruds/status-management/new-status";
import EditClinic from "cruds/clinic-management/edit-clinic";
import NewClinic from "cruds/clinic-management/new-clinic";
import EditSource from "cruds/source-management/edit-source";
import FollowUp from "cruds/appointment-management/follow-up";
import NewFollowUp from "cruds/appointment-management/follow-up/new-follow-up";
import EditFollowUp from "cruds/appointment-management/follow-up/edit-follow-up";
import NewSource from "cruds/source-management/new-source";
import EditUser from "cruds/user-management/edit-user";
import NewUser from "cruds/user-management/new-user";

/** 
  All of the routes for the Muwafiq are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Muwafiq layouts

const crudRoutes = [
  {
    key: "new-tag",
    route: "/tagManagement/addTag",
    component: <NewTag />,
    type: "tags",
  },
  {
    key: "edit-tag",
    route: "/tagManagement/updateTag/:id",
    component: <EditTag />,
    type: "tags",
  },
  {
    key: "new-status",
    route: "/statusManagement/addStatus",
    component: <NewStatus />,
    type: "status",
  },
  {
    key: "edit-status",
    route: "/statusManagement/updateStatus/:id",
    component: <EditStatus />,
    type: "status",
  },
  {
    key: "new-clinic",
    route: "/clinicManagement/addClinic",
    component: <NewClinic />,
    type: "clinics",
  },
  {
    key: "edit-clinic",
    route: "/clinicManagement/updateClinic/:id",
    component: <EditClinic />,
    type: "clinics",
  },
  {
    key: "new-source",
    route: "/sourceManagement/addSource",
    component: <NewSource />,
    type: "sources",
  },
  {
    key: "edit-source",
    route: "/sourceManagement/updateSource/:id",
    component: <EditSource />,
    type: "sources",
  },
  {
    key: "follow-up",
    route: "/appointmentManagement/followUps/:id/:clinicId",
    component: <FollowUp />,
    type: "follow_up",
  },
  {
    key: "new-follow-up",
    route: "/appointmentManagement/followUps/addFollowUp/:id/:clinicId",
    component: <NewFollowUp />,
    type: "follow_up",
  },
  {
    key: "edit-follow-up",
    route: "/appointmentManagement/followUps/updateFollowUp/:id/:clinicId",
    component: <EditFollowUp />,
    type: "follow_up",
  },
  {
    key: "new-user",
    route: "/userManagement/addUser/",
    component: <NewUser />,
    type: "users",
  },
  {
    key: "edit-user",
    route: "/userManagement/updateUser/:id",
    component: <EditUser />,
    type: "users",
  },
];

export default crudRoutes;

import CrudService from "services/cruds-service";

export const getPermissions = async (id) => { 
  try {
    const res = await CrudService.getUserWithPermissions(id); 
    const permissions = res.data;
    let jsonPermissions = [];
    permissions.map((permission) => {
      let action = permission.action;
      let subject ;
      if(action === 'read')  action = 'view';
      if(action === 'update')  action = 'edit';
        const namePermission = permission.collection.split("_");
        if (namePermission.length === 2 && namePermission[0] === 'directus' && (id === '02eb07b5-b9ee-4376-aa06-c645f0fd2bb3'|| id === '41193bb3-be6a-4d9c-8b5a-2fc804701ec9')) {
          subject = namePermission[1];
        }else{
          subject= permission.collection
        }
      if(jsonPermissions.filter((x) => x.subject === subject && x.action === action).length === 0)
        jsonPermissions = [...jsonPermissions, { action, subject }];
    });
    return jsonPermissions;
  } catch (err) {
    console.error(err);
    return null;
  }
};
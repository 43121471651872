/**
=========================================================
* Muwafiq - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Autocomplete } from "@mui/material";

// Muwafiq components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Muwafiq examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormField from "layouts/applications/wizard/components/FormField";

import CrudService from "services/cruds-service";
import authService from "services/auth-service";
import i18n from "i18n";
import moment from "moment";


const NewFollowUp = ({appintmentData, data, getFollowUps}) => {

  const navigate = useNavigate();
  const { id, clinicId } = useParams();
  const { t } = i18n;
  const format1 = "YYYY-MM-DD HH:mm:ss";
  const [value, setValue] = useState({
    id: null,
    comming_date_time: null,
    pationt: null,
    // responsible_id: null,
    // status_id: null,
    appointment_id: null,
    error: false,
    textError: "",
  });
  const [users, setUsers] = useState([]);
  const [responsibleValue, setResponsibleValue] = useState(null);
  const [statusValue, setStatusValue] = useState(null);
  const [allStatus, setAllStatus] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await authService.getProfile();
        if(users.length === 0){
          const usersRes =
            data.role.name === "Administrator"
              ? await CrudService.getResUsers()
              : await CrudService.getResUsersByClinic(clinicId);
          setIsAdmin(data.role.name === "Administrator");
          setUsers(usersRes.data);
        }
        if(allStatus.length === 0){
          const statusRes = await CrudService.getAllStatus();
          setAllStatus(statusRes.data);
        }
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, []);

  useEffect(() => {
    if (appintmentData) {
      setValue({
        ...value,
        notes:appintmentData.notes,
      });
    }
  }, [appintmentData]);

  useEffect(() => {
    if (appintmentData && users.length > 0) {
      const responsible_id = users.find(
        (responsible_id) => responsible_id.id === appintmentData.its_follow_up[appintmentData.its_follow_up.length-1].responsible_id.id
      );
      setResponsibleValue(responsible_id);
    }
  }, [users, appintmentData]);

  useEffect(() => {
    if (appintmentData && allStatus.length > 0) {
      const status_id = allStatus.find(
        (status_id) => status_id.id === appintmentData.status_id.id
      );
      setStatusValue(status_id);
    }
  }, [allStatus, appintmentData]);

  const changeHandler = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!statusValue) {
      setValue({
        ...value,
        error: true,
        textError: t("required"),
      });
      return;
    }

    if (!responsibleValue) {
      setValue({
        ...value,
        error: true,
        textError: t("required"),
      });
      return;
    }

    const followUp = {
      comming_date_time: value.comming_date_time,
      details: value.details,
      responsible_id: responsibleValue.id,
      appointment_id: id,
      status_id: statusValue?.id,
    };

    try {
      await CrudService.createFollowUp(followUp);
      await CrudService.updateAppointment({ status_id: statusValue.id, notes: value.notes, comming_date_time: value.comming_date_time, responsible_id: responsibleValue.id }, id);
      getFollowUps();
    } catch (err) {
      // if (err.hasOwnProperty("errors")) {
      //   setName({ ...name, error: true, textError: err.errors[0].detail });
      // }
      console.error(err);
      return null;
    }
  };

  return (
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={12}>
              <MDBox component="form" method="POST" onSubmit={submitHandler}>
                <Grid container justifyContent="center" spacing={2} alignItems="center">
                  <Grid item xs={12} lg={4}>
                    <MDBox 
                      display="flex"
                      alignItems="center"
                    >
                      <MDTypography 
                        variant="h6" 
                        fontWeight="medium" 
                      >
                          {t('appointmentStatus')}:
                      </MDTypography>
                      <MDBox
                      sx={{
                        backgroundColor: appintmentData.status_id?.color,
                        borderRadius: 1,
                      }}
                      px={4}
                      py={0.5}
                      mx={0.5}
                    >
                      <MDTypography variant="h6" color="white">
                        {appintmentData.status_id?.name}
                      </MDTypography>
                    </MDBox>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                  <MDBox 
                    display="flex"
                    alignItems="center"
                  >
                    <MDTypography 
                      variant="h6" 
                      fontWeight="medium" 
                    >
                        {`${t('responsible')}`}
                    </MDTypography>
                    <MDTypography 
                      variant="h6" 
                      fontWeight="medium" 
                    >
                        :
                    </MDTypography>
                    <Autocomplete
                    sx={{mx:0.5}}
                      fullWidth
                      defaultValue={null}
                      options={users}
                      getOptionLabel={(option) => {
                        if (option.data) {
                          if (option.data.first_name)
                            return `${option.data.first_name} ${option.data.last_name || ""}`;
                        } else {
                          if (option) {
                            if (option.first_name)
                              return `${option.first_name} ${option.last_name || ""}`;
                          }
                        }
                        return "";
                      }}
                      value={responsibleValue}
                      isOptionEqualToValue={(option, item) =>
                        option === item || option?.id === item?.id
                      }
                      onChange={(event, newValue) => {
                        setResponsibleValue(newValue);
                      }}
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          size="small"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          onChange={async (event) => {
                            event.target.value.length > 3 &&
                              isAdmin &&
                              (await CrudService.getResUsers(
                                `&filter[first_name][_contains]=${event.target.value}`
                              ));
                          }}
                        />
                      )}
                    />
                  </MDBox>
                  
                  </Grid>
                  <Grid item xs={12} lg={4}>
                  {data[0]?data[0]['comming_date_time']&&<MDBox 
                    display="flex"
                    alignItems="center"
                  >
                    <MDTypography 
                      variant="h6" 
                      fontWeight="medium" 
                    >
                        {t('comming_date_time')}:
                    </MDTypography>
                    <MDTypography variant="subtitle2" fontWeight="medium" >
                      {data.length>0 && moment(data[0]['comming_date_time']).format(format1)}
                    </MDTypography>
                  </MDBox>:null}
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormField
                      sx={{my:1}}
                      variant="outlined"
                      multiline
                      rows={1}
                      type="text"
                      name="notes"
                      value={value.notes}
                      onChange={changeHandler}
                      placeholder={t('notesPlaceHolder')}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormField
                      multiline
                      variant="outlined"
                      rows={5}
                      type="text"
                      InputLabelProps={{ shrink: true }}
                      name="details"
                      value={value.details}
                      onChange={changeHandler}
                      placeholder={t('detailsPlaceHolder')}
                    />
                  </Grid>
                  <Grid item xs={12} lg={2} position='relative' top={-60} left={10}>
                    <MDBox 
                      display="flex"
                      alignItems="center"
                    >
                      <Autocomplete
                        fullWidth
                        defaultValue={null}
                        options={allStatus}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.name) return option.data.name;
                          } else {
                            if (option) {
                              if (option.name) return option.name;
                            }
                          }
                          return "";
                        }}
                        value={statusValue}
                        isOptionEqualToValue={(option, item) =>
                          option === item || option?.id === item?.id
                        }
                        onChange={(event, newValue) => {
                          setStatusValue(newValue);
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            // style={{color:"red !important" }}
                            // sx={{backgroundColor: 'gray'}}
                            variant="outlined"
                            size="small"
                            // label={t("status")}
                            InputLabelProps={{ shrink: true }}
                            onChange={async (event) => {
                              event.target.value.length > 3 &&
                                (await CrudService.getAllStatus(
                                  `&filter[name][_contains]=${event.target.value}`
                                ));
                            }}
                          />
                        )}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} lg={8}  position='relative' top={-60} >
                  <MDBox 
                    display="flex"
                    justifyContent="end"
                    alignItems="center"
                  >
                    <MDTypography 
                      variant="h6" 
                      fontWeight="medium" 
                    >
                        {t('chs_comming_date_time')}:
                    </MDTypography>
                    <MDBox sx={{mx:0.5}}>
                      <FormField
                      type="datetime-local"
                      name="comming_date_time"
                      value={value.comming_date_time}
                      onChange={changeHandler}
                    />
                    </MDBox>
                  </MDBox>
                  
                  </Grid>
                  <Grid item xs={12} lg={2}  position='relative' top={-60} right={10}>
                  <MDBox 
                    display="flex"
                    justifyContent="end"
                    alignItems="center"
                  >
                   <MDButton
                      variant="gradient"
                      color="error"
                      size="small"
                      type="submit"
                    >
                      {t("addFollowUp")}
                    </MDButton>
                  </MDBox>
                  </Grid>
                </Grid>
               
                {value.error && 
                (<MDBox display="flex" flexDirection="column" px={3} my={2}>
                    <MDTypography
                      variant="caption"
                      color="error"
                      fontWeight="light"
                    >
                      {value.textError}
                    </MDTypography>
                </MDBox>
                )}
              </MDBox>
          </Grid>
        </Grid>
  );
};

export default NewFollowUp;

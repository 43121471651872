/**
=========================================================
* Muwafiq - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import { Autocomplete } from "@mui/material";

// Muwafiq components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// import { useParams } from "react-router-dom";

import CrudService from "services/cruds-service";
import AuthService from "services/auth-service";

import FormField from "layouts/applications/wizard/components/FormField";
// import console from "node:console";
import i18n from "i18n";
import moment from "moment";

const FilterAppointment = ({ setFilterUrl, filterUrl,clinicId }) => {
  const { t } = i18n;
  const [sources, setSources] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [users, setUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(null);
  // const { id, clinicId } = useParams();

  const [user, setUser] = useState({
    details:"",
    source_id: "",
    clinic_id: "",
    status_id: "",
    commingDateTimeFrom: "",
    commingDateTimeTo: "",
  });

  // const [value1, setValue1] = useState({
  //   details: "",
  //   medium: "",
  //   term: "",
  //   content: "",
  // });
  const [value, setValue] = useState(null);
  const [clinicValue, setClinicValue] = useState(null);
  const [statusValue, setStatusValue] = useState(null);
  const [responsibleValue, setResponsibleValue] = useState(null);

  useEffect(() => {
    (async () => {
      const { data } = await AuthService.getProfile();
      try {
        const usersRes =
          data.role.name === "Administrator"
            ? await CrudService.getResUsers()
            : data.role.name === "admins"
            ? await CrudService.getResUsersByClinic(clinicId)
            : null;

        usersRes && setUsers(usersRes.data);
        console.log(data.role.name);
        setIsAdmin(data.role.name);
        const sourcesRes = await CrudService.getAllSources();
        setSources(sourcesRes.data);
        const clinicsRes = await CrudService.getAllClinics();
        setClinics(clinicsRes.data);
        const allStatusRes = await CrudService.getAllStatus();
        setAllStatus(allStatusRes.data);
      } catch (err) {
        // console.error(err);
        return null;
      }
    })();
  }, []);

  const changeHandler = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      let str = filterUrl;
      const values = {
        details: user.details,
        status_id: statusValue,
        source_id: value,
        clinic_id: clinicValue,
        responsible_id: responsibleValue,
      };
      const keys = Object.keys(values);
      keys.forEach((element) => {
        if (values[element] && values[element] !== []) {
          if (typeof values[element] === "object") {
            str += `&filter[${element}][_eq]=${values[element].id}`;
          } else if(element == 'details'){
            // str += `&filter[${element}][_contains]=${values[element]}`;
            str += `&filter[_or][1][details][_contains]=${values[element]}&filter[_or][2][medium][_contains]=${values[element]}&filter[_or][3][term][_contains]=${values[element]}&filter[_or][4][content][_contains]=${values[element]}&filter[_or][5][page][_contains]=${values[element]}&filter[_or][6][campaign][_contains]=${values[element]}`;
          }
        }
      });
//       /items/bus_stops_bus_drivers?
// filter[_or][1][bus_drivers_id][_eq]=2
// &filter[_or][1][bus_stops_id][_eq]=3
// &filter[_or][2][bus_drivers_id][_eq]=4

      // const keys1 = Object.keys(value1);
      // console.log(keys1);
      // let x = 0;
      // keys1.forEach((element) => {
      //   if (value1[element]) {
      //     x += 1;
      //     console.log(value1,element,x);
      //   }
      // });
      // if (x > 1){
      //   //or
      //   keys1.forEach((element, index) => {
      //     if(value1[element]) str += `&filter[_or][${index}][${element}][_contains]=${value1[element]}`;
      //   });
      // }else if (x === 1){
      //   str += `&filter[${keys1[0]}][_contains]=${value1[keys1[0]]}`;
      // }

      if (user.commingDateTimeFrom && user.commingDateTimeTo) {
        str += `&filter[date_created][_between]=${user.commingDateTimeFrom},${user.commingDateTimeTo}`;
      }else if (user.commingDateTimeFrom) {
        str += `&filter[date_created][_gt]=${user.commingDateTimeFrom}`;
      }else if (user.commingDateTimeTo) {
        str += `&filter[date_created][_lt]=${user.commingDateTimeTo}`;
      }
      setFilterUrl(str);
    } catch (err) {
      console.log({ error: true, textError: err.message });
      return null;
    }
  };

  return (
    <MDBox
      component="form"
      method="POST"
      onSubmit={submitHandler}
      encType="multipart/form-data"
      px={5}
    >
      <MDBox display="flex" flexDirection="column" px={3} my={4}>
        <MDBox display="flex" flexDirection="column">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <FormField
                label={`${t("details")} / ${t("source-information")}`}
                placeholder="Alec"
                name="details"
                value={user.details}
                onChange={changeHandler}
              />
            </Grid>
            {/* <Grid item xs={12} sm={3}>
              <Autocomplete
                fullWidth
                defaultValue={null}
                options={allStatus}
                getOptionLabel={(option) => {
                  if (option.data) {
                    if (option.data.name) return option.data.name;
                  } else {
                    if (option) {
                      if (option.name) return option.name;
                    }
                  }
                  return "";
                }}
                value={statusValue}
                isOptionEqualToValue={(option, item) =>
                  option === item || option.id === item.id
                }
                onChange={(event, newValue) => {
                  setStatusValue(newValue);
                }}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    label={t("status")}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12} sm={3}>
              <Autocomplete
                fullWidth
                defaultValue={null}
                options={sources}
                getOptionLabel={(option) => {
                  if (option.data) {
                    if (option.data.name) return option.data.name;
                  } else {
                    if (option) {
                      if (option.name) return option.name;
                    }
                  }
                  return "";
                }}
                value={value}
                isOptionEqualToValue={(option, item) =>
                  option === item || option.id === item.id
                }
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    label={t("sources")}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              {(isAdmin === "Administrator" || isAdmin === "admins") && (
                <Autocomplete
                  fullWidth
                  defaultValue={null}
                  options={users}
                  getOptionLabel={(option) => {
                    if (option.data) {
                      if (option.data.first_name)
                        return `${option.data.first_name} ${option.data.last_name?option.data.last_name:""}`;
                    } else {
                      if (option) {
                        if (option.first_name)
                          return `${option.first_name} ${option.last_name?option.last_name:""}`;
                      }
                    }
                    return "";
                  }}
                  value={responsibleValue}
                  isOptionEqualToValue={(option, item) =>
                    option === item || option.id === item.id
                  }
                  onChange={(event, newValue) => {
                    setResponsibleValue(newValue);
                  }}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label={t("responsible")}
                      InputLabelProps={{ shrink: true }}
                      onChange={async (event) => {
                        event.target.value.length > 3 &&
                          isAdmin === "Administrator" &&
                          (await CrudService.getResUsers(
                            `&filter[first_name][_contains]=${event.target.value}`
                          ));
                      }}
                    />
                  )}
                />
              )}
            </Grid>
            {/*  */}
            {/* <Grid item xs={12} sm={3}>
              <FormField
                label={t("medium")}
                placeholder="medium"
                name="medium"
                value={value1.medium}
                onChange={changeHandler1}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                label={t("term")}
                placeholder="term"
                name="term"
                value={value1.term}
                onChange={changeHandler1}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                label={t("content")}
                placeholder="content"
                name="content"
                value={value1.content}
                onChange={changeHandler1}
              />
            </Grid> */}
            {/*  */}
            <Grid item xs={12} sm={3}>
              <FormField
                type="date"
                label={t("dateFrom")}
                name="commingDateTimeFrom"
                value={user.commingDateTimeFrom}
                onChange={changeHandler}
                error={user.error}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type="date"
                label={t("dateTo")}
                name="commingDateTimeTo"
                value={user.commingDateTimeTo}
                onChange={changeHandler}
                error={user.error}
              />
            </Grid>
            {isAdmin === "Administrator"  && (
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  fullWidth
                  defaultValue={null}
                  options={clinics}
                  getOptionLabel={(option) => {
                    if (option.data) {
                      if (option.data.name) return option.data.name;
                    } else {
                      if (option) {
                        if (option.name) return option.name;
                      }
                    }
                    return "";
                  }}
                  value={clinicValue}
                  isOptionEqualToValue={(option, item) =>
                    option === item || option.id === item.id
                  }
                  onChange={(event, newValue) => {
                    setClinicValue(newValue);
                  }}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label={t("clinics")}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
            )}
            {/* <Grid item xs={12} sm={3}>
              
            </Grid> */}
          </Grid>
          {/* <MDBox display="flex" flexDirection="column" marginTop="2rem">
            <FormField
              label={t("details")}
              placeholder="Alec"
              name="details"
              value={user.details}
              onChange={changeHandler}
            />
          </MDBox> */}
          {/* <MDBox display="flex" flexDirection="column" marginTop="2rem">
            <Autocomplete
              fullWidth
              defaultValue={null}
              options={allStatus}
              getOptionLabel={(option) => {
                if (option.data) {
                  if (option.data.name) return option.data.name;
                } else {
                  if (option) {
                    if (option.name) return option.name;
                  }
                }
                return "";
              }}
              value={statusValue}
              isOptionEqualToValue={(option, item) =>
                option === item || option.id === item.id
              }
              onChange={(event, newValue) => {
                setStatusValue(newValue);
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label={t("status")}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </MDBox> */}
          {/* <MDBox display="flex" flexDirection="column" marginTop="2rem">
            <Autocomplete
              fullWidth
              defaultValue={null}
              options={sources}
              getOptionLabel={(option) => {
                if (option.data) {
                  if (option.data.name) return option.data.name;
                } else {
                  if (option) {
                    if (option.name) return option.name;
                  }
                }
                return "";
              }}
              value={value}
              isOptionEqualToValue={(option, item) =>
                option === item || option.id === item.id
              }
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label={t("sources")}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </MDBox> */}
          {/* {filterUrl === "" && (
            <MDBox display="flex" flexDirection="column" marginTop="2rem">
              <Autocomplete
                fullWidth
                defaultValue={null}
                options={clinics}
                getOptionLabel={(option) => {
                  if (option.data) {
                    if (option.data.name) return option.data.name;
                  } else {
                    if (option) {
                      if (option.name) return option.name;
                    }
                  }
                  return "";
                }}
                value={clinicValue}
                isOptionEqualToValue={(option, item) =>
                  option === item || option.id === item.id
                }
                onChange={(event, newValue) => {
                  setClinicValue(newValue);
                }}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    label={t("clinics")}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </MDBox>
          )} */}
          {/* <FormField
            type="datetime-local"
            label={t("comming_date_time")}
            name="comming_date_time"
            value={value.comming_date_time}
            onChange={changeHandler}
            error={value.error}
          /> */}
        </MDBox>
        <MDBox ml="auto" mt={4} mb={2} display="flex" justifyContent="flex-end">
          <MDButton variant="gradient" color="dark" size="small" type="submit">
            {t("search")}
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default FilterAppointment;

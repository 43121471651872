/**
=========================================================
* Muwafiq - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "context";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Muwafiq components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Muwafiq examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
// import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
// import Calendar from "examples/Calendar";
import EventCalendar from "examples/Calendar";

import moment from "moment";
import AuthService from "services/auth-service";
import CrudService from "services/cruds-service";
import i18n from "i18n";

function Analytics() {
  const navigate = useNavigate();
  const { t } = i18n;
  const { setIsAuthenticated, getCurrentUser } = useContext(AuthContext);
  const [calenderData, setCalenderData] = useState([]);
  const [clinicId, setClinicId] = useState(null);
  const [clinicChartData, setClinicChartData] = useState({});
  const [sourceChartData, setSourceChartData] = useState({});
  const [monthChartData, setMonthChartData] = useState({});

  const getClinicChartsData = async (obj) => {
    let appointmentsClinicData = await CrudService.getClinicChartsData(obj);
    let clinics = await CrudService.getClinics();
    let labels = [];
    let data = [];
    clinics.data.map((c) => {
      const y = appointmentsClinicData.data.find((cc) => cc.clinic_id === c.id);
      data.push(y ? y.count.id : 0);
      labels.push(c.name);
    });
    setClinicChartData({
      labels,
      datasets: {
        label: "حجز",
        data,
      },
    });
  };

  const getSourceChartsData = async (obj) => {
    const profile = await AuthService.getProfile();
    setClinicId(profile.data.clinic_id.id);
    obj.clinic_id =
      profile.data.role.name === "Administrator"
        ? null
        : profile.data.clinic_id.id;

    let appointmentsSourceData = await CrudService.getSourceChartsData(obj);
    let sources = await CrudService.getSources();
    let labels = [];
    let data = [];
    sources.data.map((c) => {
      const y = appointmentsSourceData.data.find((cc) => cc.source_id === c.id);
      data.push(y ? y.count.id : 0);
      labels.push(c.name);
    });
    setSourceChartData({
      labels,
      datasets: {
        label: "حجز",
        data,
      },
    });
  };

  const getMonthChartsData = async (obj) => {
    const profile = await AuthService.getProfile();
    setClinicId(profile.data.clinic_id.id);
    obj.clinic_id =
      profile.data.role.name === "Administrator"
        ? null
        : profile.data.clinic_id.id;

    let appointmentsMonthData = await CrudService.getMonthChartsData(obj);
    let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    let labels = [];
    let data = [];
    months.map((c) => {
      const y = appointmentsMonthData.data.find(
        (cc) => cc.date_created_month === c
      );
      data.push(y ? y.count.id : 0);
      labels.push(c);
    });
    setMonthChartData({
      labels,
      datasets: {
        label: "حجز",
        data,
      },
    });
  };

  const getCalenData = async (obj) => {
    const { data } = await AuthService.getProfile();
    setClinicId(data.clinic_id.id);
    obj.clinic_id =
      data.role.name === "Administrator" ? null : data.clinic_id.id;
    let calnDataRes = await CrudService.getCalenderData(obj);
    setCalenderData(calnDataRes.status);
  };

  useEffect(() => {
    async function checkToken() {
      let user = await getCurrentUser();
      if (!user) {
        setIsAuthenticated(false);
        localStorage.removeItem("token");
      }
    }
    checkToken();
  }, []);

  const onMonthChangeHandller = (evt) => {
    const calendata = {
      str_date: evt.start,
      end_date: evt.end,
    };
    getCalenData(calendata);
    getClinicChartsData(calendata);
    getMonthChartsData(calendata);
    getSourceChartsData(calendata);
    // const clinicChartData = getClinicChartsData();
  };

  const handleDateClick = (evt) => {
    console.log(evt.date, evt.dateStr, evt);
    console.log(moment(evt.date).format("YYYY-MM-DD"));
    console.log(moment(evt.date).format("ss:mm:hh"));
  };

  const handleEventClick = (arg) => {
    navigate(
      `/appointmentManagement/followUps/${arg.event.id}/${clinicId}`
    );
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox py={3}>
        <Grid item xs={12} lg={5}>
          {useMemo(
            () => (
              <EventCalendar
                plugins={[
                  "dayGridPlugin",
                  "timeGridPlugin",
                  "interactionPlugin",
                ]}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                initialView="dayGridMonth"
                initialDate={moment().endOf("month").format("YYYY-MM-DD")}
                events={calenderData}
                selectable
                // editable
                dateClick={handleDateClick}
                datesSet={onMonthChangeHandller}
                // eventContent={renderEventContent} // custom render function
                eventClick={handleEventClick}
                // eventsSet={handleEvents}
              />
            ),
            [calenderData]
          )}
        </Grid>
        {/* <Grid container>
          <SalesByCountry />
        </Grid> */}
        <MDBox mt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title={t("clinicRepTitle")}
                  description={t("clinicRepDesc")}
                  date={t("now")}
                  chart={clinicChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title={t("clinicRepTitle")}
                  description={t("clinicRepDesc")}
                  date={t("now")}
                  chart={monthChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title={t("sourceRepTitle")}
                  description={t("sourceRepDesc")}
                  date={t("now")}
                  chart={sourceChartData}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {/* <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Bookings"
                  count={281}
                  percentage={{
                    color: "success",
                    amount: "+55%",
                    label: "than lask week",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Today's Users"
                  count="2,300"
                  percentage={{
                    color: "success",
                    amount: "+3%",
                    label: "than last month",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="store"
                  title="Revenue"
                  count="34k"
                  percentage={{
                    color: "success",
                    amount: "+1%",
                    label: "than yesterday",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="Followers"
                  count="+91"
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Just updated",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
        {/* <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={3}>
                <BookingCard
                  image={booking1}
                  title="Cozy 5 Stars Apartment"
                  description='The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Barcelona.'
                  price="$899/night"
                  location="Barcelona, Spain"
                  action={actionButtons}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={3}>
                <BookingCard
                  image={booking2}
                  title="Office Studio"
                  description='The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the night life in London, UK.'
                  price="$1.119/night"
                  location="London, UK"
                  action={actionButtons}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={3}>
                <BookingCard
                  image={booking3}
                  title="Beautiful Castle"
                  description='The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Milan.'
                  price="$459/night"
                  location="Milan, Italy"
                  action={actionButtons}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;

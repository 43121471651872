/**
=========================================================
* Muwafiq - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Muwafiq components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Muwafiq examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";

import AuthService from "services/auth-service";
import CrudService from "services/cruds-service";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { AbilityContext } from "Can";
import { useAbility } from "@casl/react";
import i18n from "i18n";
import moment from "moment";
// Material Dashboard 2 React Examples
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem from "examples/Timeline/TimelineItem";
// Material Dashboard 2 React Examples 

// @mui icons
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import { Autocomplete } from "@mui/material";
import FormField from "layouts/applications/wizard/components/FormField";
import NewFollowUp from "./new-follow-up";
// import WorldMap from "layouts/dashboards/analytics/components/WorldMap";

function FollowUp() {
  const baseUrl = process.env.REACT_APP_API_URL;
  const { id, clinicId } = useParams();
  let { state } = useLocation();
  const ability = useAbility(AbilityContext);
  const format1 = "YYYY-MM-DD HH:mm:ss";
  const { t } = i18n;
  const [data, setData] = useState([]);
  const [appintmentData, setAppintmentData] = useState(null);
  // const [sortVal, setSortVal] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [filterUrl, setFilterUrl] = useState(null);
  const [users, setUsers] = useState([]);
  const [responsibleValue, setResponsibleValue] = useState(null);
  const [allStatus, setAllStatus] = useState([]);
  const [statusValue, setStatusValue] = useState(null);
  const [notification, setNotification] = useState({
    value: false,
    text: "",
  });
  const [value, setValue] = useState({
    id: null,
    comming_date_time: null,
    pationt: null,
    responsible_id: null,
    status_id: null,
    appointment_id: null,
    error: false,
    textError: "",
  });

  useEffect(() => {
    (async () => {
      const response = await CrudService.getAppointmentById(id);
      let obj ={};
      let obj1 ={};
      obj[t('name')]=`${response.data.pationt_id?.first_name} ${response.data.pationt_id?.last_name !== null? response.data.pationt_id?.last_name: ''} ${(response.data.pationt_name !== response.data.pationt_id?.first_name && response.data.pationt_name !== null) ?`(${ response.data.pationt_name})`:``}`;
      obj[t('phone')]=response.data.pationt_id?.phone;
      obj1[t('sources')]=response.data.source;
      if(response.data.campaign) obj1[t('campaign')]=response.data.campaign; else obj1[t('campaign')]='';
      if(response.data.medium) obj1[t('medium')]=response.data.medium;else obj1[t('medium')]='';
      if(response.data.term) obj1[t('term')]=response.data.term;else obj1[t('term')]='';
      if(response.data.content) obj1[t('content')]=response.data.content;else obj1[t('content')]='';
      if(response.data.page) obj1[t('page')]=response.data.page;else obj1[t('page')]='';
      response.data.gclid === '[gclid]'?obj1[t('google')]=t('no'):obj1[t('google')]=t('yes');
      response.data.fbclid === 'IFBCLID'?obj1[t('facebook')]=t('no'):obj1[t('facebook')]=t('yes');
      response.data.pationtInfo=obj;
      response.data.sourceInfo=obj1;

      setAppintmentData(response.data);
      const { data } = await AuthService.getProfile();
      const usersRes =
        data.role.name === "Administrator"
          ? await CrudService.getResUsers()
          : await CrudService.getResUsersByClinic(clinicId);
      setIsAdmin(data.role.name === "Administrator");
      setUsers(usersRes.data);
      const responsible_id = usersRes.data.find(
        (responsible_id) => {
          return responsible_id.id === response.data.responsible_id.id
        }
      );
      setResponsibleValue(responsible_id);
      const statusRes = await CrudService.getAllStatus();
      setAllStatus(statusRes.data);
      const status_id = statusRes.data.find(
        (status_id) => status_id.id === response.data.status_id.id
      );
      setStatusValue(status_id);
    })();
  }, [data]);

  const changeHandler = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const getFollowUp = async (filUrl) => {
    const URL = `${
      filUrl ? filUrl : ""
    }&filter[appointment_id][_eq]=${id}`;
    const response = await CrudService.getFollowUps(URL);
    const newData = response.data.map((x) => {
      return {
        ...x,
        comming_date_time: x.comming_date_time?moment(x.comming_date_time).format(format1):null,
        date_created: moment(x.date_created).add(-3,'hour').format(format1),
      };
    });
    setData(newData);
  };

  useEffect(() => {     
    getFollowUp(filterUrl);
  }, [id]);

  useEffect(() => { 
    filterUrl && getFollowUp(filterUrl);
  }, [filterUrl]);

  useEffect(() => {
    if (!state) return;
    setNotification({
      value: state.value,
      text: state.text,
    });
  }, [state]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({
          value: false,
          text: "",
        });
      }, 5000);
    }
  }, [notification]);

  if(!appintmentData) return null ;

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {notification.value && (
        <MDAlert color="info" my="20px">
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )}
      {/* <WorldMap /> */}
      <MDBox pt={3} pb={3}>
        <MDBox mb={3}>
          <Card sx={{px:5}}>
            <MDBox  display="flex" justifyContent='space-between' py={5}>
              <MDBox width='80%' px={3}>
                <NewFollowUp appintmentData={appintmentData} data={data} getFollowUps={getFollowUp}/>
                <TimelineList title="">
                  {data.map((d)=>
                    <MDBox>
                      <TimelineItem
                        key={Math.random()}
                        color={d.status_id?.color}
                        icon="inventory_2"
                        title={d.user_created?`${d.user_created.first_name} ${d.user_created.last_name || ""}`:'muwafiq'}
                        avatar={d.user_created?.avatar}
                        status={d.status_id?.name}
                        dateTime={d.date_created}
                        description={d.details}
                        badges={["design"]}
                        commingDateTime={d.comming_date_time}

                      />
                    </MDBox>
                  )}
                </TimelineList>
              </MDBox>
              {appintmentData && <MDBox width='20%' pt={10} >
                <ProfileInfoCard
                  title={t("profile-information")}
                  title2={t("appointment-information")}
                  title3={t("source-information")}
                  description={appintmentData.details}
                  pInfo={appintmentData.pationtInfo||{}}
                  sInfo={appintmentData.sourceInfo||{}}
                  social={[]}
                />
              </MDBox>}
          
            </MDBox>
         </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FollowUp;

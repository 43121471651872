/**
=========================================================
* Muwafiq - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Autocomplete } from "@mui/material";

// Muwafiq components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import CrudService from "services/cruds-service";
import FormField from "layouts/applications/wizard/components/FormField";
// import console from "node:console";
import i18n from "i18n";

const FilterUser = ({ setFilterUrl, filterUrl }) => {
  const [roles, setRoles] = useState([]);
  const [clinics, setClinics] = useState([]);
  const { t } = i18n;

  const [user, setUser] = useState({
    name: "",
    email: "",
    role: "",
    clinic_id: "",
  });
  const [value, setValue] = useState(null);
  const [clinicValue, setClinicValue] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const rolesRes = await CrudService.getRoles();
        setRoles(rolesRes.data);
        const clinicsRes = await CrudService.getAllClinics();
        setClinics(clinicsRes.data);
      } catch (err) {
        // console.error(err);
        return null;
      }
    })();
  }, []);

  const changeHandler = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      let str = filterUrl;
      const values = {
        first_name: user.first_name,
        email: user.email,
        role: value,
        clinic_id: clinicValue,
      };
      const keys = Object.keys(values);
      keys.forEach((element) => {
        if (values[element] && values[element] !== []) {
          if (typeof values[element] === "object") {
            str += `&filter[${element}]=${values[element].id}`;
          } else {
            str += `&filter[${element}][_contains]=${values[element]}`;
          }
        }
        // else if(element === 'name'){
        // const filter={
        //   _and: [
        //     {
        //       first_name:{
        //             _contains: user.name
        //         }
        //     },
        //     {
        //       last_name:{
        //             _contains: user.name
        //       }
        //     }
        //   ]
        // };
        // const filter={"_or":[{"first_name":{"_contains":user.name}},{"last_name":{"_contains":user.name}}]}
        // str += `&filter=${filter}`
        // str += `&filter[_or]=[first_name[_contains]=${user.name},last_name[_contains]=${user.name}]`;
        // }
      });
      // return str;
      setFilterUrl(str);
      // =`filter[]`
    } catch (err) {
      console.log({ error: true, textError: err.message });
      return null;
    }
  };

  return (
    <MDBox
      component="form"
      method="POST"
      onSubmit={submitHandler}
      encType="multipart/form-data"
      px={5}
    >
      <MDBox display="flex" flexDirection="column" px={3} my={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label={t("firstName")}
              placeholder="Alec"
              name="first_name"
              value={user.first_name}
              onChange={changeHandler}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label={t("email")}
              placeholder="example@email.com"
              name="email"
              value={user.email}
              onChange={changeHandler}
            />
          </Grid>
        </Grid>
        <MDBox display="flex" flexDirection="column">
          <MDBox display="flex" flexDirection="column" marginTop="2rem">
            <Autocomplete
              fullWidth
              defaultValue={null}
              options={roles}
              getOptionLabel={(option) => {
                if (option.data) {
                  if (option.data.name) return option.data.name;
                } else {
                  if (option) {
                    if (option.name) return option.name;
                  }
                }
                return "";
              }}
              value={value}
              isOptionEqualToValue={(option, item) =>
                option === item || option.id === item.id
              }
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label={t("role")}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </MDBox>
          {filterUrl === "" && (
            <MDBox display="flex" flexDirection="column" marginTop="2rem">
              <Autocomplete
                fullWidth
                defaultValue={null}
                options={clinics}
                getOptionLabel={(option) => {
                  if (option.data) {
                    if (option.data.name) return option.data.name;
                  } else {
                    if (option) {
                      if (option.name) return option.name;
                    }
                  }
                  return "";
                }}
                value={clinicValue}
                isOptionEqualToValue={(option, item) =>
                  option === item || option.id === item.id
                }
                onChange={(event, newValue) => {
                  setClinicValue(newValue);
                }}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    label={t("clinics")}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </MDBox>
          )}
        </MDBox>
        <MDBox ml="auto" mt={4} mb={2} display="flex" justifyContent="flex-end">
          <MDButton variant="gradient" color="dark" size="small" type="submit">
            {t("search")}
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default FilterUser;

import HttpService from "./http.service";

class CrudService {
  _limit = 50;

  setLimit = (l) => {
    console.log(l);
    this._limit = l;
  }

  getLimit =()=>{
    return this._limit;
  }

  // users requests
  imageUpload = async (formData, id) => {
    const imageUpdate = `files`;
    const { data } = await HttpService.post(imageUpdate, formData, true);
    const payload = { avatar: data.id };
    await this.updateUser(payload, id);
    return data;
  };

  getUsers = async (filter) => {
    let usersEndpoint =
      `users?fields=*,role.name,role.id,clinic_id.id,clinic_id.name,tag_id.tag_id.name,tag_id.tag_id.id&meta=*&limit=${this._limit}`;
    if (filter) usersEndpoint += filter;
    return await HttpService.get(usersEndpoint);
  };

  getResUsers = async (filter) => {
    let usersEndpoint =
      `users?fields=*,role.name,role.id,clinic_id.id,clinic_id.name,tag_id.tag_id.name,tag_id.tag_id.id&filter[role][_neq]=c169fb14-7242-4ccb-8b15-e8077d779177`;
    if (filter) usersEndpoint += filter;
    return await HttpService.get(usersEndpoint);
  };

  getResUsersByClinic = async (id) => {
    let usersEndpoint = `users?fields=*&filter[role][_neq]=c169fb14-7242-4ccb-8b15-e8077d779177&filter[clinic_id][_eq]=${id}`;
    return await HttpService.get(usersEndpoint);
  };

  deleteUser = async (id) => {
    const endpoint = `users/${id}`;
    return await HttpService.delete(endpoint);
  };

  createUser = async (payload) => {
    const endpoint = `users`;
    return await HttpService.post(endpoint, payload);
  };

  getUser = async (id) => {
    const endpoint = `users/${id}?fields=*,role.name,role.id,clinic_id.name,clinic_id.id,tag_id.id,tag_id.tag_id.name,tag_id.tag_id.id`;
    return await HttpService.get(endpoint);
  };

  getUserWithPermissions = async (id) => {
    const endpoint = `permissions?fields=*&filter[role][_eq]=${id}`;
    return await HttpService.get(endpoint);
  };

  updateUser = async (payload, id) => {
    const endpoint = `users/${id}`;
    return await HttpService.patch(endpoint, payload);
  };

  //users tags
  removeUserTag = async (id) => {
    const endpoint = `items/users_tags/${id}`;
    return await HttpService.delete(endpoint);
  };

  addUserTag = async (payload) => {
    const endpoint = `items/users_tags`;
    return await HttpService.post(endpoint, payload);
  };

  // roles requests
  getRoles = async () => {
    const rolesEndpoint = `roles`;
    return await HttpService.get(rolesEndpoint);
  };

  deleteRole = async (id) => {
    const endpoint = `roles/${id}`;
    return await HttpService.delete(endpoint);
  };

  createRole = async (payload) => {
    const endpoint = `roles`;
    return await HttpService.post(endpoint, payload);
  };

  updateRole = async (payload, id) => {
    const endpoint = `roles/${id}`;
    return await HttpService.patch(endpoint, payload);
  };

  getRole = async (id) => {
    const endpoint = `roles/${id}`;
    return await HttpService.get(endpoint);
  };

  // clinics requests
  getClinics = async () => {
    const clinicsEndpoint = `items/clinics`;
    return await HttpService.get(clinicsEndpoint);
  };

  deleteClinic = async (id) => {
    const endpoint = `items/clinics/${id}`;
    return await HttpService.delete(endpoint);
  };

  createClinic = async (payload) => {
    const endpoint = `items/clinics`;
    return await HttpService.post(endpoint, payload);
  };

  updateClinic = async (payload, id) => {
    const endpoint = `items/clinics/${id}`;
    return await HttpService.patch(endpoint, payload);
  };

  getClinic = async (id) => {
    const endpoint = `items/clinics/${id}`;
    return await HttpService.get(endpoint);
  };

  // tag requests
  getTags = async (filter) => {
    let tagsEndpoint = `items/tags?fields=*&meta=*&limit=${this._limit}`;
    if (filter) tagsEndpoint += filter;
    return await HttpService.get(tagsEndpoint);
  };

  deleteTag = async (id) => {
    const endpoint = `items/tags/${id}`;
    return await HttpService.delete(endpoint);
  };

  createTag = async (payload) => {
    const endpoint = `items/tags`;
    return await HttpService.post(endpoint, payload);
  };

  getTag = async (id) => {
    const endpoint = `items/tags/${id}`;
    return await HttpService.get(endpoint);
  };

  updateTag = async (payload, id) => {
    const endpoint = `items/tags/${id}`;
    return await HttpService.patch(endpoint, payload);
  };

  // status requests
  getAllStatus = async (filter) => {
    //&limit=${this._limit}
    let statusEndpoint = `items/status?fields=*&meta=*`;
    if (filter) statusEndpoint += filter;
    return await HttpService.get(statusEndpoint);
  };

  deleteStatus = async (id) => {
    const endpoint = `items/status/${id}`;
    return await HttpService.delete(endpoint);
  };

  createStatus = async (payload) => {
    const endpoint = `items/status`;
    return await HttpService.post(endpoint, payload);
  };

  getStatus = async (id) => {
    const endpoint = `items/status/${id}`;
    return await HttpService.get(endpoint);
  };

  updateStatus = async (payload, id) => {
    const endpoint = `items/status/${id}`;
    return await HttpService.patch(endpoint, payload);
  };

  // clinic requests
  getAllClinics = async (filter) => {
    let clinicsEndpoint = `items/clinics?fields=*&meta=*`;
    if (filter) clinicsEndpoint += filter;
    return await HttpService.get(clinicsEndpoint);
  };

  getClinics = async (filter) => {
    let clinicsEndpoint = `items/clinics?fields=*&meta=*&limit=${this._limit}`;
    if (filter) clinicsEndpoint += filter;
    return await HttpService.get(clinicsEndpoint);
  };

  deleteClinic = async (id) => {
    const endpoint = `items/clinics/${id}`;
    return await HttpService.delete(endpoint);
  };

  createClinic = async (payload) => {
    const endpoint = `items/clinics`;
    return await HttpService.post(endpoint, payload);
  };

  getClinic = async (id) => {
    const endpoint = `items/clinics/${id}`;
    return await HttpService.get(endpoint);
  };

  updateClinic = async (payload, id) => {
    const endpoint = `items/clinics/${id}`;
    return await HttpService.patch(endpoint, payload);
  };

  // appointment requests
  getAppointments = async (filter) => {
    let appointmentsEndpoint =
      `items/appointments?fields=*,responsible_id.first_name,responsible_id.last_name,responsible_id.id,responsible_id.role.name,responsible_id.avatar,pationt_id.*,clinic_id.name,clinic_id.id,source_id.name,source_id.id,status_id.id,status_id.name,status_id.color,its_follow_up.*&meta=*&limit=${this._limit}`;
    if (filter) appointmentsEndpoint += filter;
    return await HttpService.get(appointmentsEndpoint);
  };

  updateAppointment = async (payload, id) => {
    const endpoint = `items/appointments/${id}`;
    return await HttpService.patch(endpoint, payload);
  };

  deleteAppointment = async (id) => {
    const endpoint = `items/appointments/${id}`;
    return await HttpService.delete(endpoint);
  };

  getAppointmentById = async (id) => {
    const endpoint = `items/appointments/${id}?fields=*,responsible_id.first_name,responsible_id.last_name,responsible_id.id,responsible_id.role.name,responsible_id.avatar,pationt_id.*,clinic_id.name,clinic_id.id,source_id.name,source_id.id,status_id.id,status_id.name,status_id.color,its_follow_up.*,its_follow_up.responsible_id.first_name,its_follow_up.responsible_id.last_name,its_follow_up.responsible_id.id,its_follow_up.responsible_id.role.name,its_follow_up.responsible_id.avatar`;
    return await HttpService.get(endpoint);
  };

  // follow-up requests
  getFollowUps = async (filter) => {
    let followUpEndpoint =
      `items/follow_up?fields=*,responsible_id.first_name,responsible_id.last_name,responsible_id.id,responsible_id.role.name,responsible_id.avatar,user_created.first_name,user_created.last_name,user_created.id,user_created.role.name,user_created.avatar,appointment_id.pationt_id.*,appointment_id.id,appointment_id.details,status_id.color,status_id.name,status_id.id&sort=-date_created`;
    if (filter) followUpEndpoint += filter;
    return await HttpService.get(followUpEndpoint);
  };

  getFollowUp = async (id) => {
    const endpoint = `items/follow_up/${id}`;
    return await HttpService.get(endpoint);
  };

  updateFollowUp = async (payload, id) => {
    const endpoint = `items/follow_up/${id}`;
    return await HttpService.patch(endpoint, payload);
  };

  createFollowUp = async (payload) => {
    const endpoint = `items/follow_up`;
    return await HttpService.post(endpoint, payload);
  };

  deleteFollowUp = async (id) => {
    const endpoint = `items/follow_up/${id}`;
    return await HttpService.delete(endpoint);
  };

  // source requests
  getAllSources = async (filter) => {
    let sourcesEndpoint = `items/sources?fields=*&meta=*`;
    if (filter) sourcesEndpoint += filter;
    return await HttpService.get(sourcesEndpoint);
  };

  getSources = async (filter) => {
    let sourcesEndpoint = `items/sources?fields=*&meta=*&limit=${this._limit}`;
    if (filter) sourcesEndpoint += filter;
    return await HttpService.get(sourcesEndpoint);
  };

  deleteSource = async (id) => {
    const endpoint = `items/sources/${id}`;
    return await HttpService.delete(endpoint);
  };

  createSource = async (payload) => {
    const endpoint = `items/sources`;
    return await HttpService.post(endpoint, payload);
  };

  getSource = async (id) => {
    const endpoint = `items/sources/${id}`;
    return await HttpService.get(endpoint);
  };

  updateSource = async (payload, id) => {
    const endpoint = `items/sources/${id}`;
    return await HttpService.patch(endpoint, payload);
  };

  getCalenderData = async (payload) => {
    const endpoint = `custom/getCalenderData`;
    return await HttpService.post(endpoint, payload);
  };

  distributeFollowUps = async (cId,uId) => {
    const endpoint = `custom/distributeFollowUps?clinikId=${cId}&userId=${uId}`;
    return await HttpService.post(endpoint);
  };

  getMonthChartsData = async (payload) => {
    let endpoint = `items/appointments?aggregate[count]=id&groupBy[]=month(date_created)`;
    if (payload.clinic_id)
      endpoint += `&filter[clinic_id][_eq]=${payload.clinic_id}`;
    return await HttpService.get(endpoint, payload);
  };

  getSourceChartsData = async (payload) => {
    let endpoint = `items/appointments?aggregate[count]=id&groupBy[]=source_id`;
    if (payload.clinic_id)
      endpoint += `&filter[clinic_id][_eq]=${payload.clinic_id}`;
    return await HttpService.get(endpoint, payload);
  };

  getClinicChartsData = async (payload) => {
    let endpoint = `items/appointments?fields=id,clinic_id.name,clinic_id.id&aggregate[count]=id&groupBy[]=clinic_id`;
    if (payload.clinic_id)
      endpoint += `&filter[clinic_id][_eq]=${payload.clinic_id}`;
    return await HttpService.get(endpoint, payload);
  };

  getCountAppointmentsStatus = async (URL) => {
    let endpoint = `items/appointments?fields=id,clinic_id.name,clinic_id.id&aggregate[count]=id`;
    if (URL) endpoint += URL;
    return await HttpService.get(endpoint);
  };
}

export default new CrudService();

/**
=========================================================
* Muwafiq - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Muwafiq components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Muwafiq base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import i18n from 'i18n'

function ProfileInfoCard({ title, title2, title3, description, pInfo, sInfo, shadow }) {
  const sLabels = [];
  const pLabels = [];
  const values = [];
  const values1 = [];
  const { t } = i18n;
    // Convert this form `objectKey` of the object key in to this `object key`
    Object.keys(pInfo).forEach((el) => {
      if (el.match(/[A-Z\s]+/)) {
        const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
        const newElement = el.replace(
          uppercaseLetter,
          ` ${uppercaseLetter}`
          // ` ${uppercaseLetter.toLowerCase()}`
        );
        pLabels.push(newElement);
      } else {
        pLabels.push(el); 
      }
    });
  
    // Convert this form `objectKey` of the object key in to this `object key`
    Object.keys(sInfo).forEach((el) => {
      if (el.match(/[A-Z\s]+/)) {
        const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
        const newElement = el.replace(
          uppercaseLetter,
          ` ${uppercaseLetter}`
        );
        sLabels.push(newElement);
      } else {
        sLabels.push(el); 
      }
    });
  // Push the object values into the values array
  Object.values(pInfo).forEach((el) => values.push(el));

  // Render the card info items
  const renderPationtItems = pLabels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={2}>
      <MDTypography
        variant="button"
        fontWeight="bold"
        textTransform="capitalize"
      >
        {label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values[key]}
      </MDTypography>
    </MDBox>
  ));
  
  // Push the object values into the values array
  Object.values(sInfo).forEach((el) => values1.push(el));

  // Render the card info items
  const renderSourceItems = sLabels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={2}>
      <MDTypography
        variant="button"
        fontWeight="bold"
        textTransform="capitalize"
      >
        {label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values1[key]}
      </MDTypography>
    </MDBox>
  ));

  return (
    <MDBox>
       <Card sx={{  boxShadow: !shadow && "none" }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title} 
        </MDTypography>
        <MDTypography
          onClick={()=>window.location.href = `https://wa.me/${pInfo[t('phone')]}`}
          variant="body2"
          color="secondary"
          sx={{ cursor: 'pointer' }}
        >
          <Icon>call</Icon>
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        {renderPationtItems}
      </MDBox>
    </Card>
    <Card sx={{  boxShadow: !shadow && "none", mt:2 }}>
      <MDBox
        pt={2}
        px={2}
      >
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title2}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox  lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="light">
            {description}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox
          px={2}
        >
          <MDTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
          >
            {title3}
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          {renderSourceItems}
        </MDBox>
    </Card>
  
    </MDBox>
   );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  social: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default ProfileInfoCard;

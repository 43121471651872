/**
=========================================================
* Muwafiq - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Muwafiq components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Muwafiq examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { Tooltip, IconButton } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StatusOnIcon from "@mui/icons-material/Wifi";
import StatusOffIcon from "@mui/icons-material/WifiOff";

import CrudService from "services/cruds-service";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { AbilityContext } from "Can";
import { useAbility } from "@casl/react";
import { Can } from "Can";
import { subject } from "@casl/ability";
import AuthService from "services/auth-service";
import i18n from "i18n";
import CsvDownloadButton from 'react-json-to-csv'

import FilterUser from "./filter-user";

function UserManagement() {
  let { state } = useLocation(); 
  const { t } = i18n;
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);
  const [sortVal, setSortVal] = useState(null);
  const [sortUrl, setSortUrl] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [userInfo, setUserInfo] = useState(false);
  const [user, setUsers] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [limitChange, setLimitChange] = useState(false);

  const [filterUrl, setFilterUrl] = useState(null);
  const [defaultFilterUrl, setDefaultFilterUrl] = useState("");
  const [notification, setNotification] = useState({
    value: false,
    text: "",
  });

  const baseUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);

  const [tableData, setTableData] = useState([]);

  const getUsers = async (filUrl) => {
    const URL = filUrl ? `${filUrl}&page=${page}` : `&page=${page}`;
    const response = await CrudService.getUsers(URL);
    setUsers(response.data);
    setMeta(response.meta);
  };

  useEffect(() => {
    (async () => {
      const { data } = await AuthService.getProfile();
      setIsAdmin(
        data.role.name === "Administrator" || data.role.name === "admins"
      );
      setUserInfo(true);
      if (data.role.name !== "Administrator") {
        setFilterUrl(
          `${filterUrl || ""}&filter[clinic_id][_eq]=${data.clinic_id.id}`
        );
        setDefaultFilterUrl(`&filter[clinic_id][_eq]=${data.clinic_id.id}`);
      } else getUsers();
    })();
  }, []);

  useEffect(() => {
    userInfo && getUsers(`${filterUrl || ""}${sortUrl}`);
  }, [page]);

    useEffect(() => {
    userInfo && getUsers(`${filterUrl || ""}${sortUrl}`);
    setLimitChange(false);
  }, [limitChange]);

  useEffect(() => {
    if (sortVal) {
      setSortUrl(
        `&sort=${sortVal.desc ? "-" : "+"}${
          sortVal.id === "name" ? "first_name" : sortVal.id
        }`
      );
    }
  }, [sortVal]);

  // useEffect(() => {
  //   if (sortVal) {
  //     setFilterUrl(
  //       `${filterUrl || ""}${sortUrl}`
  //     );
  //   }
  // }, [sortUrl]);

  useEffect(() => {
    if (userInfo && page === 1) getUsers(`${filterUrl || ""}${sortUrl}`);
    else if (userInfo && page !== 1) setPage(1);
  }, [filterUrl, sortUrl]);

  useEffect(() => {
    if (!state) return;
    setNotification({
      value: state.value,
      text: state.text,
    });
  }, [state]);

  useEffect(() => {
    setTableData(getRows(user));
  }, [user]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({
          value: false,
          text: "",
        });
      }, 5000);
    }
  }, [notification]);

  const clickAddHandler = () => {
    navigate("/userManagement/addUser");
  };

  const clickEditHandler = (id) => {
    navigate(`/userManagement/updateUser/${id}`);
  };

  const updateStatusHandler = async (e, data) => {
    let obj;
    if (data.status === "active") obj = { status: "suspended" };
    else obj = { status: "active" };
    await CrudService.updateUser(obj, data.id);
    getUsers(filterUrl);
    setNotification({
      value: true,
      text: t("statusChanged"),
    });
    if (!window.confirm(t("confirmDistributeFollowUps"))) {
        e.nativeEvent.stopImmediatePropagation();
    } else {
      await CrudService.distributeFollowUps(data.clinicId,data.id);
      setNotification({
        value: true,
        text: t("distributeFollowUpsSuccessfully"),
      });
    }
  };

  const clickDeleteHandler = async (e, id) => {
    try {
      if (!window.confirm(t("confirmDelete"))) {
        e.nativeEvent.stopImmediatePropagation();
      } else {
        await CrudService.deleteUser(id);
        // the delete does not send a response
        // so I need to get again the tags to set it and this way the table gets updated -> it goes to the useEffect with data dependecy
        getUsers(filterUrl);
        setNotification({
          value: true,
          text: t("deleteSuccessfully"),
        });
      }
    } catch (err) {
      // it sends error is the category is associated with an item
      console.error(err);
      if (err.hasOwnProperty("errors")) {
        setNotification({
          value: true,
          text: err.errors[0].title,
        });
      }
      return null;
    }
  };

  const getRows = (info) => {
    let updatedInfo = info.map((row) => {
      let roleName = row.role?.name;
      return {
        type: "users",
        id: row.id,
        name: `${row.first_name} ${row.last_name?row.last_name:""}`,
        image: row.avatar ? `${baseUrl}assets/${row.avatar}` : null,
        email: row.email,
        role: roleName,
        status: row.status,
        clinicId:row.clinic_id?.id,
        roleId:row.role?.id,
        communications_num:row.communications_num
      };
    });
    return updatedInfo;
  };

  const getCsvRows = (info) => {
    let updatedInfo = info.map((row) => {
      let roleName = row.role?.name;
      let csv = {
        id: row.id,
        name: `${row.first_name} ${row.last_name?row.last_name:""}`,
        email: row.email,
        role: roleName,
        status: row.status,
        clinicId:row.clinic_id?.name
      };
       const keys = Object.keys(csv);
      let csvObj={}
      keys.forEach((element) => {
        csvObj[t(element)]=csv[element];
      })
      return csvObj;
    });
    return updatedInfo;
  };

  const dataTableData = {
    columns: [
      {
        Header: t("image"),
        accessor: "image",
        width: "15%",
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <MDAvatar src={value} alt="profile-image" size="xl" shadow="sm" />
            </>
          );
        },
      },
      { Header: t("name"), accessor: "name", width: "15%" },
      { Header: t("email"), accessor: "email", width: "20%" },
      {
        Header: t("role"),
        accessor: "role",
        width: "15%",
        disableSortBy: true,
      },
      {
        Header: t("actions"),
        disableSortBy: true,
        accessor: "",
        Cell: (info) => {
         if(info.cell.row.original.role) return (
            <MDBox display="flex" alignItems="center">
              {isAdmin ? (
                <>
                  {!info.cell.row.original.communications_num&&<Tooltip
                    title={t("deleteUser")}
                    onClick={(e) =>
                      clickDeleteHandler(e, info.cell.row.original.id)
                    }
                  >
                    <IconButton>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>}
                  <Tooltip
                    title={t("updateUser")}
                    onClick={() => clickEditHandler(info.cell.row.original.id)}
                  >
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                    {info.cell.row.original.roleId === "fd74712a-3576-4028-b0cd-329ac5e30340" && info.cell.row.original.status === "suspended" ? (
                    <Tooltip
                      title={t("changeStatus")}
                      onClick={(e) =>
                        updateStatusHandler(e,info.cell.row.original)
                      }
                    >
                      <IconButton>
                        <StatusOffIcon />
                      </IconButton>
                    </Tooltip>
                  ) :info.cell.row.original.roleId === "fd74712a-3576-4028-b0cd-329ac5e30340"? (
                    <Tooltip
                      title={t("changeStatus")}
                      onClick={(e) =>
                        updateStatusHandler(e,info.cell.row.original)
                      }
                    >
                      <IconButton>
                        <StatusOnIcon />
                      </IconButton>
                    </Tooltip>
                  ):null}

                </>
              ) : (
                <>
                  {!info.cell.row.original.communications_num&&<Can
                    I="delete"
                    this={subject("users", info.cell.row.original)}
                  >
                    <Tooltip
                      title={t("deleteUser")}
                      onClick={(e) =>
                        clickDeleteHandler(e, info.cell.row.original.id)
                      }
                    >
                      <IconButton>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Can>}
                  <Can I="edit" this={subject("users", info.cell.row.original)}>
                    <Tooltip
                      title={"updateUser"}
                      onClick={() =>
                        clickEditHandler(info.cell.row.original.id)
                      }
                    >
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Can>
                </>
              )}
            </MDBox>
          );
        },
      },
      ,
    ],
    rows: tableData,
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {notification.value && (
        <MDAlert color="info" my="20px">
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )}
      <MDBox pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox
              p={3}
              lineHeight={1}
              display="flex"
              justifyContent="space-between"
            >
              <MDTypography variant="h5" fontWeight="medium">
                {t("userManagement")}
              </MDTypography>
              <MDBox display="flex" >
                {(ability.can("create", "users") || isAdmin) && (
                  <MDButton
                    variant="gradient"
                    color="dark"
                    size="small"
                    type="submit"
                    onClick={clickAddHandler}
                    mx={1}
                  >
                    + {t("addUser")}
                  </MDButton>
                )}
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  type="submit"
                  onClick={()=>setShowFilter(!showFilter)}
                 sx={{mx:1}}
                >
                    {t("filter")}
                </MDButton>  
                {isAdmin && <CsvDownloadButton
                  data={getCsvRows(user)}
                  style={{ backgroundColor: 'rgb(37 37 39)',color: '#fff', fontSize : "15px" , fontWeight : "bold", padding:"6px 24px", borderRadius:"6px"}}
                >
                  {t('export')}
                </CsvDownloadButton>}
              </MDBox>

            </MDBox>
            {showFilter && <FilterUser
              setFilterUrl={setFilterUrl}
              filterUrl={defaultFilterUrl}
            />}
            <DataTable
              setLimitChange={setLimitChange}
              table={dataTableData}
              meta={meta}
              setPage={setPage}
              setSortVal={setSortVal}
              pageNum={page}
            />
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserManagement;

/**
=========================================================
* Muwafiq - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Muwafiq are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Muwafiq layouts
import Analytics from "layouts/dashboards/analytics";

import UserProfile from "cruds/user-profile";
import TagManagement from "cruds/tag-management";
import StatusManagement from "cruds/status-management";
import ClinicManagement from "cruds/clinic-management";
import SourceManagement from "cruds/source-management";
import AppointmentManagement from "cruds/appointment-management";
import UserManagement from "cruds/user-management";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";

// Muwafiq components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";

import i18n from "i18n";

const { t } = i18n;

const routes = [
  {
    type: "collapse",
    name: "Bruce Mars",
    key: "user-name",
    icon: <MDAvatar src={profilePicture} alt="Bruce Mars" size="sm" />,
    collapse: [
      {
        name: t("profile"),
        key: "user-profile",
        route: "/examples-api/user-profile",
        component: <UserProfile />,
      },
      {
        name: "Logout",
        key: "logout",
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: t("dashboards"),
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: t("analytics"),
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
    ],
  },
  { type: "title", title: t("management"), key: "crud-pages" },
  {
    type: "collapse",
    name: t("userManagement"),
    key: "user-management",
    icon: <GroupAddOutlinedIcon size="sm" />,
    //,
    collapse: [
      {
        name: t("users"),
        key: "add-user",
        route: "/userManagement",
        component: <UserManagement />,
        type: "users",
      },
      {
        name: t("tagManagement"),
        key: "tagManagement",
        route: "/userManagement/tagManagement",
        component: <TagManagement />,
        type: "tags",
      },
    ],
  },
  {
    type: "collapse",
    name: t("appointmentManagement"),
    key: "appointment-management",
    icon: <EditCalendarOutlinedIcon fontSize="medium" />,
    collapse: [
      {
        name: t("appointments"),
        key: "appointments",
        route: "/appointmentManagement/appointments",
        component: <AppointmentManagement />,
        type: "appointments",
      },
      // {
      //   name: t("status-management"),
      //   key: "status-management",
      //   route: "/appointmentManagement/statusManagement",
      //   component: <StatusManagement />,
      //   type: "status",
      // },
      {
        name: t("clinicManagement"),
        key: "clinic-management",
        route: "/appointmentManagement/clinicManagement",
        component: <ClinicManagement />,
        type: "clinics",
      },
      // {
      //   name: t("sourceManagement"),
      //   key: "source-management",
      //   route: "/appointmentManagement/sourceManagement",
      //   component: <SourceManagement />,
      //   type: "sources",
      // },
    ],
  },
];

export default routes;

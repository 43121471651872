/**
=========================================================
* Muwafiq - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Muwafiq components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function MiniStatisticsCardWithoutIcon({
  bgColor,
  title,
  count,
  percentage,
  direction,
}) {

  return (
    <Card sx={{ overflow: "hidden" }}>
      <MDBox
          ml={direction === "left" ? 2 : 0}
          lineHeight={1}
          textAlign='center'
          bgColor={percentage.color}
          py={1}
          justifyContent='center'
        >
          <MDTypography
            variant="h5"
            color="white"
            // opacity={bgColor === "white" ? 1 : 0.7}
            textTransform="capitalize"
            fontWeight={title.fontWeight}
          >
            {title.text}
          </MDTypography>
          <MDBox display='flex' justifyContent='center' alignItems='center'>
            <MDTypography
              variant="h5"
              fontWeight="bold"
              color="white"
              // w={'100%'}
              px={2}
            >
              {count}
            </MDTypography>
            <MDTypography
              variant="button" 
              color="white"
              fontWeight="bold"
            >
              {percentage.text}
            </MDTypography>
          </MDBox>
        </MDBox>
    </Card>
  );
}

// // Setting default values for the props of MiniStatisticsCardWithoutIcon
// MiniStatisticsCardWithoutIcon.defaultProps = {
//   bgColor: "white",
//   title: {
//     fontWeight: "light",
//     text: "",
//   },
//   percentage: {
//     color: "success",
//     text: "",
//   },
//   direction: "right",
// };

// // Typechecking props for the MiniStatisticsCardWithoutIcon
// MiniStatisticsCardWithoutIcon.propTypes = {
//   bgColor: PropTypes.oneOf([
//     "white",
//     "primary",
//     "secondary",
//     "info",
//     "success",
//     "warning",
//     "error",
//     "dark",
//   ]),
//   title: PropTypes.PropTypes.shape({
//     fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
//     text: PropTypes.string,
//   }),
//   count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//   percentage: PropTypes.shape({
//     color: PropTypes.oneOf([
//       "primary",
//       "secondary",
//       "info",
//       "success",
//       "warning",
//       "error",
//       "dark",
//       "white",
//     ]),
//     text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   }),
//   icon: PropTypes.shape({
//     color: PropTypes.oneOf([
//       "primary",
//       "secondary",
//       "info",
//       "success",
//       "warning",
//       "error",
//       "dark",
//     ]),
//     component: PropTypes.node.isRequired,
//   }).isRequired,
//   direction: PropTypes.oneOf(["right", "left"]),
// };

export default MiniStatisticsCardWithoutIcon;

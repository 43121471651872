/**
=========================================================
* Muwafiq - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Autocomplete } from "@mui/material";

// Muwafiq components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Muwafiq examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormField from "layouts/applications/wizard/components/FormField";

import CrudService from "services/cruds-service";
import authService from "services/auth-service";
import i18n from "i18n";

const EditFollowUp = () => {
  const { id, clinicId } = useParams();

  const navigate = useNavigate();
  const { t } = i18n;
  const [value, setValue] = useState({
    id: null,
    comming_date_time: null,
    pationt: null,
    responsible_id: null,
    status_id: null,
    appointment_id: null,
    error: false,
    textError: "",
  });
  const [users, setUsers] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [statusValue, setStatusValue] = useState(null);
  const [responsibleValue, setResponsibleValue] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);

  useEffect(() => {
    (async () => {
      const { data } = await authService.getProfile();
      try {
        const usersRes =
          data.role.name === "Administrator"
            ? await CrudService.getResUsers()
            : data.role.name === "admins"
            ? await CrudService.getResUsersByClinic(clinicId)
            : null;

        usersRes && setUsers(usersRes.data);
        setIsAdmin(data.role.name);
        const statusRes = await CrudService.getAllStatus();
        setAllStatus(statusRes.data);
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, []);

  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        const { data } = await CrudService.getFollowUp(id);
        setValue({
          id: data.id,
          details: data.details,
          comming_date_time: data.comming_date_time,
          pationt: data.appointment_id.pationt_id,
          responsible_id: data.responsible_id,
          status_id: data.status_id,
          appointment_id: data.appointment_id,
        });

        const authData = await authService.getProfile();
        const usersRes =
          authData.data.role.name === "Administrator"
            ? await CrudService.getResUsers()
            : authData.data.role.name === "admins"
            ? await CrudService.getResUsersByClinic(clinicId)
            : null;

        usersRes && setUsers(usersRes.data);
        setIsAdmin(data.role.name);
        const statusRes = await CrudService.getAllStatus();
        setAllStatus(statusRes.data);
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, [id]);

  useEffect(() => {
    if (value.responsible_id && users.length > 0) {
      const responsible_id = users.find(
        (responsible_id) => responsible_id.id === value.responsible_id
      );
      setResponsibleValue(responsible_id);
    }
  }, [users, value]);

  useEffect(() => {
    if (value.status_id && allStatus.length > 0) {
      const status_id = allStatus.find(
        (status_id) => status_id.id === value.status_id
      );
      setStatusValue(status_id);
    }
  }, [allStatus, value]);

  const changeHandler = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    // if (value.comming_date_time?.trim().length < 1) {
    //   setValue({
    //     ...value,
    //     error: true,
    //     textError: t("required"),
    //   });
    //   return;
    // }

    if (!responsibleValue) {
      setValue({
        ...value,
        error: true,
        textError: t("required"),
      });
      return;
    }

    const followUp = {
      id: value.id.toString(),
      comming_date_time: value.comming_date_time,
      details: value.details,
      responsible_id: responsibleValue
        ? responsibleValue.id
        : value.responsible_id,
      status_id: statusValue?.id,
    };

    try {
      await CrudService.updateFollowUp(followUp, followUp.id);
      // await CrudService.updateAppointment({ status_id: statusValue.id }, id);

      navigate(
        `/appointmentManagement/followUps/${value.appointment_id}/${clinicId}`,
        {
          state: { value: true, text: t("updateSuccessfully") },
        }
      );
    } catch (err) {
      if (err.hasOwnProperty("errors")) {
        setValue({ ...value, error: true, textError: err.errors[0].detail });
      }
      console.error(err);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbTitle={value.text} />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {t("updateFollowUp")}
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox component="form" method="POST" onSubmit={submitHandler}>
                <MDBox display="flex" flexDirection="column" px={3} my={2}>
                  {value.error && (
                    <MDTypography
                      variant="caption"
                      color="error"
                      fontWeight="light"
                    >
                      {value.textError}
                    </MDTypography>
                  )}
                  <MDBox p={1}>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      marginTop="2rem"
                    >
                      <Autocomplete
                        disabled
                        fullWidth
                        defaultValue={null}
                        options={allStatus}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.name) return option.data.name;
                          } else {
                            if (option) {
                              if (option.name) return option.name;
                            }
                          }
                          return "";
                        }}
                        value={statusValue}
                        isOptionEqualToValue={(option, item) =>
                          option === item || option.id === item.id
                        }
                        onChange={(event, newValue) => {
                          setStatusValue(newValue);
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label={t("status")}
                            InputLabelProps={{ shrink: true }}
                            onChange={async (event) => {
                              event.target.value.length > 3 &&
                                (await CrudService.getAllStatus(
                                  `&filter[name][_contains]=${event.target.value}`
                                ));
                            }}
                          />
                        )}
                      />
                    </MDBox>
                    {(isAdmin === "Administrator" || isAdmin === "admins") && (
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        marginTop="2rem"
                      >
                        <Autocomplete
                          fullWidth
                          defaultValue={null}
                          options={users}
                          getOptionLabel={(option) => {
                            if (option.data) {
                              if (option.data.first_name)
                                return `${option.data.first_name} ${option.data?.last_name || ""}`;
                            } else {
                              if (option) {
                                if (option.first_name)
                                  return `${option.first_name} ${option?.last_name || ""}`;
                              }
                            }
                            return "";
                          }}
                          value={responsibleValue}
                          isOptionEqualToValue={(option, item) =>
                            option === item || option.id === item.id
                          }
                          onChange={(event, newValue) => {
                            setResponsibleValue(newValue);
                          }}
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              label={t("responsible")}
                              InputLabelProps={{ shrink: true }}
                              onChange={async (event) => {
                                event.target.value.length > 3 &&
                                  isAdmin === "Administrator" &&
                                  (await CrudService.getResUsers(
                                    `&filter[first_name][_contains]=${event.target.value}`
                                  ));
                              }}
                            />
                          )}
                        />
                      </MDBox>
                    )}
                    <FormField
                      multiline
                      rows={5}
                      type="text"
                      label={t("details")}
                      name="details"
                      value={value.details}
                      onChange={changeHandler}
                      // error={value.error}
                    />
                    <FormField
                      type="datetime-local"
                      label={t("comming_date_time")}
                      name="comming_date_time"
                      value={value.comming_date_time}
                      onChange={changeHandler}
                      error={value.error}
                    />
                  </MDBox>
                  <MDBox
                    ml="auto"
                    mt={4}
                    mb={2}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <MDBox mx={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        px={2}
                        mx={2}
                        onClick={() =>
                          navigate(
                            `/appointmentManagement/followUps/${value.appointment_id}/${clinicId}`,
                            {
                              state: { value: false, text: "" },
                            }
                          )
                        }
                      >
                        {t("back")}
                      </MDButton>
                    </MDBox>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      type="submit"
                    >
                      {t("save")}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditFollowUp;
